<template>
  <div
    class="app-card-clickable room-card"
    style="display: grid; gap: 1rem"
    @click="$emit('click')"
  >
    <div class="room-image">
      <img
        v-if="!imageLoadFailed && display.image"
        :src="display.image"
        alt="room"
        @error="imageLoadFailed = true"
      />
      <div
        v-else
        style="
          background: var(--color-icon-background);
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1.5rem;
        "
      >
        <IconPlaceholderRoom
          style="color: var(--color-icon)"
          height="100%"
          width="100%"
        />
      </div>
    </div>
    <div class="room-header">
      <p class="room-header-title">{{ room.name }}</p>
      <div class="room-header-price">
        <p>{{ display.price }}</p>
        <p class="price-per-night">{{ calcPricePerNight }}</p>
      </div>
    </div>
    <div class="room-amenities">
      <template v-for="amenity in room.amenitiesList">
        <img height="20" width="20" :src="amenity.imageUrl" alt="amenity" />
        <p>{{ amenity.name }}</p>
      </template>
    </div>
    <AppCardInfo v-if="showInfo" :label="infoLabel" class="app-card-info" />
  </div>
</template>

<script>
import IconPlaceholderRoom from '../../icons/placeholders/IconPlaceholderRoom.vue'
import AppCardInfo from '../../AppCardInfo.vue'
export default {
  name: 'RoomCard',
  props: {
    room: Object,
    hotel: Object,
    showInfo: Boolean,
    infoLabel: String,
  },
  data() {
    return { imageLoadFailed: false }
  },
  computed: {
    display() {
      return {
        price: this.$formatPrice(this.room?.price?.total),
        image: this.room?.mediaList?.[0],
      }
    },
    roomAmenities() {
      return this.room.amenitiesList.slice(0, 3)
    },
    calcPricePerNight() {
      const msPerDay = 1000 * 60 * 60 * 24
      const arrivalDate = new Date(this.hotel.checkInDatetime)
      const returnDate = new Date(this.hotel.checkOutDatetime)
      const utc1 = Date.UTC(
        arrivalDate.getFullYear(),
        arrivalDate.getMonth(),
        arrivalDate.getDate(),
      )
      const utc2 = Date.UTC(
        returnDate.getFullYear(),
        returnDate.getMonth(),
        returnDate.getDate(),
      )
      return (
        this.$formatPrice(this.room.price.total / ((utc2 - utc1) / msPerDay)) +
        ' / night'
      )
    },
  },
  components: { IconPlaceholderRoom, AppCardInfo },
}
</script>

<style scoped lang="scss">
.room-image {
  width: 100%;
  height: 125px;
  border-radius: 8px;
  overflow: hidden;
  & > * {
    width: 100%;
    height: 100%;
  }
}
.room-header {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;
  align-items: center;
}
.room-header-title {
  font-size: 13px;
  font-family: LuciferSans;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 16px;
  letter-spacing: 0.09em;
}
.room-header-price {
  font-size: 17px;
  font-family: SpaceMono;
  font-weight: 400;
  text-align: right;
  line-height: 17px;
  letter-spacing: 0.02em;
}
.price-per-night {
  opacity: 40%;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0;
  margin-top: 0.5rem;
}
.room-amenities {
  display: grid;
  gap: 0.5rem;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  & > p {
    font-family: DDin;
    font-size: 13px;
  }
}

@media (hover: hover) {
  .room-card:hover .app-card-info {
    --box-shadow-height: 2px;
  }
}
</style>
